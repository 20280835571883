import {
  validateEmail,
  validateRequired,
  Validator,
} from 'src/application/utils/validators';

// eslint-disable-next-line max-len
export const SET_IS_MODULE_LOADED = '[form] sec-get-in-touch module/isModuleLoaded';
export type SetIsModuleLoadedAction = {
  type: typeof SET_IS_MODULE_LOADED,
};
export const setIsModuleLoaded = {
  type: SET_IS_MODULE_LOADED,
};

// eslint-disable-next-line max-len
export const SUBMIT_FORM_SEC_GET_IN_TOUCH = '[form] submit form sec get in touch';
export type SubmitForm = {
  type: typeof SUBMIT_FORM_SEC_GET_IN_TOUCH;
}
export const submitForm = {
  type: SUBMIT_FORM_SEC_GET_IN_TOUCH,
};

// eslint-disable-next-line max-len
export const SET_FORM_LOADING_SEC_GET_IN_TOUCH = '[forms] set loading sec get in touch';
export type SetFormLoading = {
  type: typeof SET_FORM_LOADING_SEC_GET_IN_TOUCH;
  payload: {
    isLoading: boolean;
  }
};

export const setFormLoading = (
  isLoading: boolean,
): SetFormLoading => ({
  type: SET_FORM_LOADING_SEC_GET_IN_TOUCH,
  payload: {
    isLoading,
  },
});

// eslint-disable-next-line max-len
export const SET_FORM_SUCCESS_SEC_GET_IN_TOUCH = '[forms] set success sec get in touch';
export type SetFormSuccess = {
  type: typeof SET_FORM_SUCCESS_SEC_GET_IN_TOUCH;
  payload: {
    isSuccess: boolean;
  }
};

export const setFormSuccess = (
  isSuccess: boolean,
): SetFormSuccess => ({
  type: SET_FORM_SUCCESS_SEC_GET_IN_TOUCH,
  payload: {
    isSuccess,
  },
});

// eslint-disable-next-line max-len
export const SET_INPUT_SEC_GET_IN_TOUCH = '[forms] input change sec get in touch';

export type SetInput = {
  type: typeof SET_INPUT_SEC_GET_IN_TOUCH;
  payload: {
    fieldName: string;
    value: string;
    validators: Validator[];
  }
}

// eslint-disable-next-line max-len
export const SET_CHECKBOX_SEC_GET_IN_TOUCH = '[forms] checkbox change sec get in touch';

export type SetCheckbox = {
  type: typeof SET_CHECKBOX_SEC_GET_IN_TOUCH;
  payload: {
    groupName: string;
    option: string;
    isChecked: boolean;
    validators: Validator[];
  }
}
export type SetInputValidated = {
  type: typeof SET_INPUT_SEC_GET_IN_TOUCH;
  payload: {
    [fieldName: string]: {
      value: string;
      error: string;
    }
  }
}
export type SetCheckboxValidated = {
  type: typeof SET_CHECKBOX_SEC_GET_IN_TOUCH;
  payload: {
    [groupName: string]: {
      value: { [key: string]: boolean; };
      error: string;
    }
  }
}

export const setRequiredInput = (fieldName: string) => (
  value: string,
): SetInput => ({
  type: SET_INPUT_SEC_GET_IN_TOUCH,
  payload: {
    fieldName,
    value,
    validators: [validateRequired],
  },
});

export const setInput = (fieldName: string) => (
  value: string,
): SetInput => ({
  type: SET_INPUT_SEC_GET_IN_TOUCH,
  payload: {
    fieldName,
    value,
    validators: [],
  },
});

export const setRequiredEmail = (fieldName: string) => (
  value: string,
): SetInput => ({
  type: SET_INPUT_SEC_GET_IN_TOUCH,
  payload: {
    fieldName,
    value,
    validators: [validateRequired, validateEmail],
  },
});

export const SET_MULTI_SELECT_GET_IN_TOUCH = '[get-in-touch] multiselect/set';
export type SetMultiSelect = {
  type: typeof SET_MULTI_SELECT_GET_IN_TOUCH;
  payload: {
    fieldName: string;
    values: string[];
    validators: Validator[];
  }
}
export type SetMultiSelectValidated = {
  type: typeof SET_MULTI_SELECT_GET_IN_TOUCH;
  payload: {
    [fieldName: string]: {
      values: string[];
      error: string;
    }
  }
}
export const setRequiredMultiSelect = (
  fieldName: string,
) => (
  values: string[],
): SetMultiSelect => ({
  type: SET_MULTI_SELECT_GET_IN_TOUCH,
  payload: {
    fieldName,
    values,
    validators: [validateRequired],
  },
});

export const setCheckbox = (
  groupName: string,
  option: string,
) => (
  isChecked: boolean,
): SetCheckbox => ({
  type: SET_CHECKBOX_SEC_GET_IN_TOUCH,
  payload: {
    groupName,
    option,
    isChecked,
    validators: [],
  },
});

// eslint-disable-next-line max-len
export const SET_PHONE_INPUT_SEC_GET_IN_TOUCH = '[forms] phone number change sec get in touch';
export type SetPhoneInput = {
  type: typeof SET_PHONE_INPUT_SEC_GET_IN_TOUCH;
  payload: {
    countryCode: string;
    number: string;
    isRequired: boolean;
  }
}
export type SetPhoneInputValidated = {
  type: typeof SET_PHONE_INPUT_SEC_GET_IN_TOUCH;
  payload: {
    phoneNumber: {
      countryCode: string;
      number: string;
      error: string;
    };
  }
}

export const setRequiredPhoneInput = (
  countryCode: string,
  number: string,
): SetPhoneInput => ({
  type: SET_PHONE_INPUT_SEC_GET_IN_TOUCH,
  payload: {
    countryCode,
    number,
    isRequired: true,
  },
});

export const SET_CAMPAIGN_GET_IN_TOUCH = 'set campaign data get in touch';
export type SetCampaign = {
  type: typeof SET_CAMPAIGN_GET_IN_TOUCH;
  payload: {
    name: string;
    source: string;
    medium: string;
  }
}
export const setCampaign = (
  campaign: SetCampaign['payload'],
): SetCampaign => ({
  type: SET_CAMPAIGN_GET_IN_TOUCH,
  payload: campaign,
});

export const SET_GENERAL_ERROR_GET_IN_TOUCH = 'set general error get in touch';
export type SetGeneralErrorMessage = {
  type: typeof SET_GENERAL_ERROR_GET_IN_TOUCH;
  payload: string;
}

export const setGeneralErrorMessage = (
  message: SetGeneralErrorMessage['payload'],
): SetGeneralErrorMessage => ({
  type: SET_GENERAL_ERROR_GET_IN_TOUCH,
  payload: message,
});

export const FORM_LOADED_GET_IN_TOUCH = 'form loaded get in touch';
export type FormLoaded = {
  type: typeof FORM_LOADED_GET_IN_TOUCH,
};

export const formLoaded = {
  type: FORM_LOADED_GET_IN_TOUCH,
};

export const RESET_FORM_GET_IN_TOUCH = '[get-in-touch] form reset';
export type ResetForm = {
  type: typeof RESET_FORM_GET_IN_TOUCH,
};

export const resetForm: ResetForm = {
  type: RESET_FORM_GET_IN_TOUCH,
};

export const SET_LOCATION_FIELDS_GET_IN_TOUCH = 'set location get in touch';
export type SetLocationFields = {
  type: typeof SET_LOCATION_FIELDS_GET_IN_TOUCH,
};

export const setLocationFields = {
  type: SET_LOCATION_FIELDS_GET_IN_TOUCH,
};

export type SecGetInTouchActions =
  | SetIsModuleLoadedAction
  | SetInputValidated
  | SetPhoneInputValidated
  | SetCheckboxValidated
  | SetFormLoading
  | SetFormSuccess
  | SetCampaign
  | SetGeneralErrorMessage
  | ResetForm
  | SetMultiSelectValidated;
