export const countries = [
  { value: 'AF', label: '🇦🇫 Afghanistan' },
  { value: 'DZ', label: '🇩🇿 Algeria' },
  { value: 'AO', label: '🇦🇴 Angola' },
  { value: 'AR', label: '🇦🇷 Argentina' },
  { value: 'AW', label: '🇦🇼 Aruba' },
  { value: 'AU', label: '🇦🇺 Australia' },
  { value: 'AT', label: '🇦🇹 Austria' },
  { value: 'BH', label: '🇧🇸 Bahrain' },
  { value: 'BD', label: '🇧🇩 Bangladesh' },
  { value: 'BE', label: '🇧🇪 Belgium' },
  { value: 'BO', label: '🇧🇴 Bolivia, Plurinational State Of' },
  { value: 'BR', label: '🇧🇷 Brazil' },
  { value: 'BG', label: '🇧🇬 Bulgaria' },
  { value: 'KH', label: '🇰🇭 Cambodia' },
  { value: 'CA', label: '🇨🇦 Canada' },
  { value: 'CL', label: '🇨🇱 Chile' },
  { value: 'CN', label: '🇨🇳 China' },
  { value: 'CO', label: '🇨🇴 Colombia' },
  { value: 'CR', label: '🇨🇷 Costa Rica' },
  { value: 'CW', label: '🇨🇼 Curaçao' },
  { value: 'CY', label: '🇨🇾 Cyprus' },
  { value: 'CZ', label: '🇨🇿 Czech Republic' },
  { value: 'DK', label: '🇩🇰 Denmark' },
  { value: 'DO', label: '🇨🇩 Dominican Republic' },
  { value: 'EC', label: '🇪🇨 Ecuador' },
  { value: 'EG', label: '🇪🇬 Egypt' },
  { value: 'SV', label: '🇸🇻 El Salvador' },
  { value: 'FI', label: '🇫🇮 Finland' },
  { value: 'FR', label: '🇫🇷 France' },
  { value: 'DE', label: '🇩🇪 Germany' },
  { value: 'GH', label: '🇬🇭 Ghana' },
  { value: 'GR', label: '🇬🇷 Greece' },
  { value: 'GU', label: '🇬🇺 Guam' },
  { value: 'GT', label: '🇬🇹 Guatemala' },
  { value: 'HN', label: '🇭🇳 Honduras' },
  { value: 'HK', label: '🇭🇰 Hong Kong SAR, China' },
  { value: 'HU', label: '🇭🇺 Hungary' },
  { value: 'IN', label: '🇮🇳 India' },
  { value: 'ID', label: '🇮🇩 Indonesia' },
  { value: 'IQ', label: '🇮🇶 Iraq' },
  { value: 'IE', label: '🇮🇪 Ireland' },
  { value: 'IT', label: '🇮🇹 Italy' },
  { value: 'JM', label: '🇯🇲 Jamaica' },
  { value: 'JP', label: '🇯🇵 Japan' },
  { value: 'JO', label: '🇯🇴 Jordan' },
  { value: 'KZ', label: '🇰🇿 Kazakhstan' },
  { value: 'KE', label: '🇰🇪 Kenya' },
  { value: 'KR', label: '🇰🇷 Korea, Republic Of' },
  { value: 'KW', label: '🇰🇼 Kuwait' },
  { value: 'LB', label: '🇱🇧 Lebanon' },
  { value: 'LT', label: '🇱🇹 Lithuania' },
  { value: 'MG', label: '🇲🇬 Madagascar' },
  { value: 'MY', label: '🇲🇾 Malaysia' },
  { value: 'MV', label: '🇲🇻 Maldives' },
  { value: 'MU', label: '🇲🇺 Mauritius' },
  { value: 'MX', label: '🇲🇽 Mexico' },
  { value: 'MA', label: '🇲🇦 Morocco' },
  { value: 'MM', label: '🇲🇲 Myanmar' },
  { value: 'NP', label: '🇳🇵 Nepal' },
  { value: 'NL', label: '🇳🇱 Netherlands' },
  { value: 'NZ', label: '🇳🇿 New Zealand' },
  { value: 'NI', label: '🇳🇮 Nicaragua' },
  { value: 'NG', label: '🇳🇬 Nigeria' },
  { value: 'MP', label: '🏳 Northern Mariana Islands' },
  { value: 'NO', label: '🇳🇴 Norway' },
  { value: 'OM', label: '🇴🇲 Oman' },
  { value: 'PK', label: '🇵🇰 Pakistan' },
  { value: 'PA', label: '🇵🇦 Panama' },
  { value: 'PG', label: '🇵🇬 Papua New Guinea' },
  { value: 'PY', label: '🇵🇾 Paraguay' },
  { value: 'PE', label: '🇵🇪 Peru' },
  { value: 'PH', label: '🇵🇭 Philippines' },
  { value: 'PL', label: '🇵🇱 Poland' },
  { value: 'PT', label: '🇵🇹 Portugal' },
  { value: 'QA', label: '🇶🇦 Qatar' },
  { value: 'RO', label: '🇷🇴 Romania' },
  { value: 'RU', label: '🇷🇺 Russian Federation' },
  { value: 'SA', label: '🇸🇦 Saudi Arabia' },
  { value: 'SG', label: '🇸🇬 Singapore' },
  { value: 'SK', label: '🇸🇰 Slovakia' },
  { value: 'SI', label: '🇸🇮 Slovenia' },
  { value: 'ZA', label: '🇿🇦 South Africa' },
  { value: 'ES', label: '🇪🇸 Spain' },
  { value: 'LK', label: '🇱🇰 Sri Lanka' },
  { value: 'SE', label: '🇸🇪 Sweden' },
  { value: 'CH', label: '🇨🇭 Switzerland' },
  { value: 'TW', label: '🇹🇼 Taiwan, ROC' },
  { value: 'TH', label: '🇹🇭 Thailand' },
  { value: 'TT', label: '🇹🇹 Trinidad And Tobago' },
  { value: 'TN', label: '🇹🇳 Tunisia' },
  { value: 'TR', label: '🇹🇷 Turkey' },
  { value: 'UG', label: '🇺🇬 Uganda' },
  { value: 'UA', label: '🇺🇦 Ukraine' },
  { value: 'AE', label: '🇦🇪 United Arab Emirates' },
  { value: 'GB', label: '🇬🇧 United Kingdom' },
  { value: 'US', label: '🇺🇸 United States' },
  { value: 'UY', label: '🇺🇾 Uruguay' },
  { value: 'VE', label: '🇻🇪 Venezuela, Bolivarian Republic Of' },
  { value: 'VN', label: '🇻🇳 Vietnam' },
];
