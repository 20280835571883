import { Middleware } from 'redux';
import { AppError } from 'src/application/entities/app-error';
import { Services } from 'src/application/interfaces';
import { AppState } from 'src/application/state';
import { Store } from 'src/application/middleware';
import { countries } from 'application/constants/countries';
import { validateEmail, validateRequired } from 'application/utils/validators';
import {
  LOAD_COMPONENT_SUCCESS,
  LoadComponentSuccess,
} from 'application/core/control/actions';
import {
  setIsModuleLoaded,
  setFormLoading,
  setFormSuccess,
  SetInput,
  SetInputValidated,
  SetPhoneInput,
  SetPhoneInputValidated,
  setRequiredInput,
  setRequiredPhoneInput,
  SET_INPUT_SEC_GET_IN_TOUCH,
  SET_CHECKBOX_SEC_GET_IN_TOUCH,
  SET_PHONE_INPUT_SEC_GET_IN_TOUCH,
  SubmitForm,
  SUBMIT_FORM_SEC_GET_IN_TOUCH,
  SetCheckbox,
  SetCheckboxValidated,
  setRequiredEmail,
  FORM_LOADED_GET_IN_TOUCH,
  FormLoaded,
  setLocationFields,
  SET_LOCATION_FIELDS_GET_IN_TOUCH,
  SetLocationFields,
  setGeneralErrorMessage,
  formLoaded,
  SET_MULTI_SELECT_GET_IN_TOUCH,
  SetMultiSelect,
  SetMultiSelectValidated,
  setRequiredMultiSelect,
} from './actions';

export const loadComponentSuccessMiddleware = (): Middleware => ({
  dispatch,
}) => (next) => async (
  action: LoadComponentSuccess,
): Promise<void> => {
  if (
    action.type === LOAD_COMPONENT_SUCCESS
  ) {
    dispatch(setIsModuleLoaded);
  }
  next(action);
};

export const InputChanged = (
): Middleware => () => (next) => (action: SetInput): void => {
  if (action.type === SET_INPUT_SEC_GET_IN_TOUCH) {
    const { payload } = action as SetInput;

    let error = '';
    payload.validators.forEach((validate) => {
      const errorCode = validate(payload.value);
      if (errorCode) {
        error = errorCode;
      }
    });

    const validatedPayload: SetInputValidated['payload'] = {
      [payload.fieldName]: {
        value: payload.value,
        error,
      },
    };

    next({
      ...action,
      payload: validatedPayload,
    });
    return;
  }

  next(action);
};

export const MultiSelectChanged = (
): Middleware => () => (next) => (action: SetMultiSelect): void => {
  if (action.type === SET_MULTI_SELECT_GET_IN_TOUCH) {
    const { payload } = action as SetMultiSelect;

    let error = '';
    payload.validators.forEach((validate) => {
      const errorCode = validate(payload.values);
      if (errorCode) {
        error = errorCode;
      }
    });

    const validatedPayload: SetMultiSelectValidated['payload'] = {
      [payload.fieldName]: {
        values: payload.values,
        error,
      },
    };

    next({
      ...action,
      payload: validatedPayload,
    });
    return;
  }

  next(action);
};

export const CheckboxChanged = (): Middleware => ({
  getState,
}: { getState: () => AppState }) => (next) => (action: SetCheckbox): void => {
  if (action.type === SET_CHECKBOX_SEC_GET_IN_TOUCH) {
    const {
      payload: {
        groupName,
        option,
        isChecked,
        validators,
      },
    } = action as SetCheckbox;

    let error = '';

    const { secGetInTouch } = getState();

    if (!secGetInTouch) {
      throw new Error('Failed loading module');
    }

    const existingBoxes = (
      secGetInTouch as {[key: string]: any} // eslint-disable-line
    )[groupName].value as {[key: string]: boolean};

    const updatedBoxes = {
      ...existingBoxes,
      [option]: isChecked,
    };

    validators.forEach((validate) => {
      const errorCode = validate(updatedBoxes);
      if (errorCode) {
        error = errorCode;
      }
    });

    const validatedPayload: SetCheckboxValidated['payload'] = {
      [groupName]: {
        value: updatedBoxes,
        error,
      },
    };

    next({
      ...action,
      payload: validatedPayload,
    });
    return;
  }

  next(action);
};

export const PhoneInputChanged = (
): Middleware => () => (next) => (action): void => {
  if (action.type === SET_PHONE_INPUT_SEC_GET_IN_TOUCH) {
    const { payload } = action as SetPhoneInput;

    const validatedPayload: SetPhoneInputValidated['payload'] = {
      phoneNumber: {
        countryCode: payload.countryCode,
        number: payload.number,
        error: payload.isRequired ? validateRequired(payload.number) : '',
      },
    };

    next({
      ...action,
      payload: validatedPayload,
    });
    return;
  }

  next(action);
};

export const FormSubmitted = ({ api }: Services): Middleware => ({
  dispatch,
  getState,
}: Store) => (next) => async (action: SubmitForm): Promise<void> => {
  next(action);

  if (action.type === SUBMIT_FORM_SEC_GET_IN_TOUCH) {
    if (!api?.shipaEcommerce?.CRM) {
      return;
    }

    dispatch(setFormLoading(true));

    const { secGetInTouch } = getState();

    if (!secGetInTouch) {
      throw new Error('Failed loading module');
    }

    const {
      firstName,
      lastName,
      phoneNumber,
      email,
      companyName,
      websiteLink,
      country,
      city,
      ordersPerMonth,
      hasTradeLicense,
      kindOfCommodities,
      additionalDetails,
      agreeToTermsAndConditions,
      recaptcha,
      campaign,
    } = secGetInTouch;

    let canSubmit = true;

    dispatch(setGeneralErrorMessage(''));
    if (validateRequired(firstName.value)) {
      dispatch(setRequiredInput('firstName')(firstName.value));
      canSubmit = false;
    }
    if (validateRequired(lastName.value)) {
      dispatch(setRequiredInput('lastName')(lastName.value));
      canSubmit = false;
    }
    if (validateRequired(phoneNumber.number)) {
      dispatch(setRequiredPhoneInput(
        phoneNumber.countryCode,
        phoneNumber.number,
      ));
      canSubmit = false;
    }
    if (validateEmail(email.value)) {
      dispatch(setRequiredEmail('email')(email.value));
      canSubmit = false;
    }
    if (validateRequired(companyName.value)) {
      dispatch(setRequiredInput('companyName')(companyName.value));
      canSubmit = false;
    }
    if (validateRequired(country.value)) {
      dispatch(setRequiredInput('country')(country.value));
      canSubmit = false;
    }
    if (validateRequired(city.value)) {
      dispatch(setRequiredInput('city')(city.value));
      canSubmit = false;
    }
    if (validateRequired(ordersPerMonth.value)) {
      dispatch(setRequiredInput('ordersPerMonth')(ordersPerMonth.value));
      canSubmit = false;
    }
    if (validateRequired(hasTradeLicense.value)) {
      dispatch(setRequiredInput('hasTradeLicense')(hasTradeLicense.value));
      canSubmit = false;
    }
    if (validateRequired(kindOfCommodities.values)) {
      dispatch(
        setRequiredMultiSelect('kindOfCommodities')(kindOfCommodities.values),
      );
      canSubmit = false;
    }
    if (validateRequired(recaptcha.value)) {
      dispatch(setRequiredInput('recaptcha')(recaptcha.value));
      canSubmit = false;
    }

    if (!canSubmit) {
      dispatch(setFormLoading(false));
      return;
    }

    try {
      await api.shipaEcommerce.CRM.createLead({
        firstName: firstName.value,
        lastName: lastName.value,
        phoneNumber,
        email: email.value,
        companyName: companyName.value,
        websiteLink: websiteLink.value,
        country: country.value,
        city: city.value,
        ordersPerMonth: ordersPerMonth.value,
        hasTradeLicense: hasTradeLicense.value,
        kindOfCommodities: kindOfCommodities.values,
        additionalDetails: additionalDetails.value,
        agreeToTermsAndConditions: agreeToTermsAndConditions.value,
        campaign,
      });
      dispatch(setFormSuccess(true));
      dispatch(setFormLoading(false));
    } catch (error) {
      if (error instanceof AppError && error.code === 'validationError') {
        dispatch(setGeneralErrorMessage(error.description));
      } else {
        dispatch(setGeneralErrorMessage(
          'Something went wrong. Please try again later.',
        ));
      }
      // eslint-disable-next-line no-console
      console.error(error.message);
    }

    dispatch(setFormLoading(false));
  }
};

export const ComponentLoadedSuccessFlow = (): Middleware => ({
  dispatch,
}: Store) => (next) => (action: LoadComponentSuccess): void => {
  next(action);

  if (
    action.type === LOAD_COMPONENT_SUCCESS
    && action.payload === 'sec-get-in-touch'
  ) {
    dispatch(formLoaded);
  }
};

export const FormLoadedFlow = (): Middleware => ({
  dispatch,
}: Store) => (next) => (action: FormLoaded): void => {
  next(action);

  if (action.type === FORM_LOADED_GET_IN_TOUCH) {
    dispatch(setLocationFields);
  }
};

export const SetLocationFlow = ({
  api,
}: Services): Middleware => ({
  dispatch,
}: Store) => (next) => async (action: SetLocationFields): Promise<void> => {
  next(action);

  if (!api?.other?.getGeolocation) {
    return;
  }

  if (action.type === SET_LOCATION_FIELDS_GET_IN_TOUCH) {
    try {
      const geo = await api?.other?.getGeolocation();
      const countryLabel = countries.find(
        (country) => country.value === geo?.countryCode,
      )?.label;

      if (countryLabel) {
        dispatch(setRequiredInput('country')(countryLabel));
      }
      if (geo?.city) {
        dispatch(setRequiredInput('city')(geo.city));
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
};

export default [
  loadComponentSuccessMiddleware,
  InputChanged,
  PhoneInputChanged,
  FormSubmitted,
  CheckboxChanged,
  FormLoadedFlow,
  SetLocationFlow,
  ComponentLoadedSuccessFlow,
  MultiSelectChanged,
];
